/* eslint-disable no-shadow */
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from '@core_modules/product/services/graphql/schema';
import * as ActionSchema from '@core_modules/product/services/graphql/actionSchema';
import * as CustomizableSchema from '@core_modules/product/services/graphql/customizableSchema';
import { getLoginInfo } from '@helper_auth';

let isLogin = 0;
if (typeof window !== 'undefined') {
    isLogin = getLoginInfo();
}

const USING_INTERNAL = true;
const config = (isUsingInternal) => {
    const context = isUsingInternal ? { request: 'internal' } : {};

    return {
        context,
    };
};

const defaultConfig = {

};

const useInternal = {
    context: {
        request: 'internal',
    },
};

export const getProduct = (config = {}, options = {}) => useQuery(Schema.getProduct(config), {
    ...defaultConfig,
    ...options,
});

export const getProductPrice = (config = {}, options = {}) => useLazyQuery(Schema.getProductPrice(config), {
    ...defaultConfig,
    ...options,
    fetchPolicy: 'no-cache',
});

export const getProductLabel = (config = {}, options = {}) => useQuery(Schema.getProductLabel(config), {
    ...defaultConfig,
    ...options,
});

export const getRelatedProduct = (config, options = {}) => useQuery(Schema.getRelatedProduct(config), {
    ...defaultConfig,
    ...options,
});

export const getUpsellProduct = (config = {}, options = {}) => useQuery(Schema.getUpsellProduct(config), {
    ...defaultConfig,
    ...options,
});

export const getCustomizableOption = (urlpath) => useLazyQuery(CustomizableSchema.getCustomizableOption(urlpath), {
    ...defaultConfig,
    fetchPolicy: 'no-cache',
});

export const smartProductTabs = (params) => useLazyQuery(Schema.smartProductTabs(), {
    ...defaultConfig,
    variables: {
        ...params,
    },
    skip: typeof window === 'undefined',
    fetchPolicy: 'cache-and-network',
});

export const getProductBySku = (config, params) => useQuery(Schema.getProductBySku(config), {
    ...params,
    ...defaultConfig,
    context: {
        request: 'internal',
    },
});

export const getReviews = (params) => useQuery(Schema.getReview(), {
    variables: {
        ...params,
    },
    fetchPolicy: 'cache-and-network',
});

export const addReview = () => useMutation(Schema.addReview, {
    context: {
        request: 'internal',
    },
});

export const getCustomerSettings = () => useQuery(Schema.getCustomerSettings, {
    context: {
        request: 'internal',
    },
});

export const addWishlist = () => useMutation(Schema.addWishlist, {
    context: {
        request: 'internal',
    },
});

export const getConfigurableProduct = (config = {}, params = {}) => useQuery(Schema.getConfigurableProduct(config), {
    ...defaultConfig,
    ...params,
});
export const getBundleProduct = (sku) => useQuery(Schema.getBundleProduct(sku), {
    ...defaultConfig,
});
export const getDownloadroduct = (sku) => useQuery(Schema.getDownloadProduct(sku), {
    ...defaultConfig,
});
export const getGroupedProduct = (config = {}, params = {}) => useQuery(Schema.getGroupedProduct(config), {
    ...defaultConfig,
    ...params,
});

export const getSeller = (options = {}) => useQuery(Schema.getSeller, { ...options, ...useInternal });

// actions add to cart

export const addSimpleProductsToCart = () => useMutation(ActionSchema.addSimpleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addVirtualProductToCart = () => useMutation(ActionSchema.addVirtualProductToCart, {
    context: {
        request: 'internal',
    },
});

export const addDownloadProductToCart = () => useMutation(ActionSchema.addDownloadableProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addConfigProductsToCart = () => useMutation(ActionSchema.addConfigProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addConfigurableProductsToCart = () => useMutation(ActionSchema.addConfigurableProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addBundleProductsToCart = () => useMutation(ActionSchema.addBundleProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const addGiftCardProductsToCart = () => useMutation(ActionSchema.addGiftCardProductsToCart, {
    context: {
        request: 'internal',
    },
});

export const getGuestCartId = () => useMutation(ActionSchema.createCartIdGuest, {
    context: {
        request: 'internal',
    },
});

export const getCustomerCartId = () => useLazyQuery(ActionSchema.getCartIdUser, {
    context: {
        request: 'internal',
    },
    fetchPolicy: 'no-cache',
});

export const addProductsToCart = () => useMutation(ActionSchema.addProductToCart, {
    context: {
        request: 'internal',
    },
});

export const getProductBannerLite = (urlpath, options = {}) => useLazyQuery(Schema.getProductBannerLite(urlpath), {
    ...defaultConfig,
    ...options,
});

export const createCompareList = () => useMutation(Schema.createCompareList, {
    context: {
        request: 'internal',
    },
});

export const getCustomerUid = (options = {}) => useLazyQuery(Schema.getCustomerUid, {
    ...options,
    ...config(USING_INTERNAL),
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
});

export const addProductsToCompareList = () => useMutation(Schema.addProductsToCompareList, {
    context: {
        request: 'internal',
    },
});

export const CtqGetCart = () => useQuery(Schema.CtqGetCart, {
    context: {
        request: 'internal',
    },
    skip: !isLogin,
});

export const getSpecFormProduct = (config = {}) => useQuery(Schema.getSpecFormProduct, {
    ...config,
    context: {
        request: 'internal',
    },
});

// Add Product to Quote List
export const CtqQuoteListAddProduct = () => useMutation(ActionSchema.CtqQuoteListAddProduct, {
    context: {
        request: 'internal',
    },
});

export const getRecentViewedProducts = (variables) => useMutation(Schema.getRecentViewedProducts, {
    variables,
    context: {
        request: 'internal',
    },
});

export const CtqIsEnabled = () => useQuery(Schema.CtqIsEnabled);

export const getProductId = (variables) => useLazyQuery(Schema.getProductId, {
    context: {
        request: 'internal',
    },
    variables,
});

export default {
    getProduct, CtqIsEnabled, CtqGetCart, getRecentViewedProducts,
};
