import { gql } from '@apollo/client';
import { modules } from '@config';

export const createCartIdGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const createEmptyCartGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const addSimpleProductsToCart = gql`
mutation addSimpleProductsToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],
      $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
    addSimpleProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
      }
    }) {
      cart {
        id
      }
    }
  }
`;

export const addVirtualProductToCart = gql`
mutation addVirtualProductToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],      
      $entered_options: [EnteredOptionInput] 
      ` : ''}
) {
    addVirtualProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
      }
    }) {
      cart {
        id
      }
    }
  }
`;

export const addDownloadableProductsToCart = gql`
mutation(
  $cartId : String!,
  $sku: String!,
  $qty: Float!,
  $download_product_link: [DownloadableProductLinksInput],
  ${modules.product.customizableOptions.enabled
        ? `
    $customizable_options: [CustomizableOptionInput],      
    $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
  addDownloadableProductsToCart(
    input: {
      cart_id: $cartId
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          sku: $sku,
          quantity: $qty,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
        downloadable_product_links: $download_product_link
      }
    }
  ) {
    cart {
      items {
        product {
          sku
        }
        quantity
        ... on DownloadableCartItem {
          links {
            title
            price
          }
          samples {
            title
            sample_url
          }
        }
      }
    }
  }
}
`;

export const addConfigProductsToCart = gql`
mutation (
  $cartId: String!,
  $qty: Float!,
  $sku: String!,
  $parentSku: String!,  
  ${modules.product.customizableOptions.enabled
        ? `
    $customizable_options: [CustomizableOptionInput],
    $entered_options: [EnteredOptionInput] 
  ` : ''}
) {
  addConfigurableProductsToCart(
    input: {
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity : $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}            
        parent_sku: $parentSku
        }
      }
    }
  ) {
    cart {
      id
    }
  }
}
`;

export const addConfigurableProductsToCart = gql`
  mutation addConfigurableProductsToCart(
    $cartId: String!,
    $cartItems: [CartItemInput!]!
  ) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        id
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const addBundleProductsToCart = gql`
mutation (
  $cartId: String!,
  $cartItems: [BundleProductCartItemInput]!,
) {
      addBundleProductsToCart(
        input: {
          cart_id: $cartId
          cart_items: $cartItems
        }
      ) {
        cart {
          id
        }
      }
    }
`;

export const addProductToCart = gql`
    mutation addProductToCart(
        $cartId: String!,
        $cartItems: [CartItemInput!]!
    ) {
        addProductsToCart(
            cartId: $cartId
            cartItems: $cartItems
        ) {
            cart {
                id
            }
            user_errors {
                code
                message
            }
        }
    }
`;

export const addGiftCardProductsToCart = gql`
mutation addGiftCardProductsToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    $awGcInput: awGcGiftCardOptionInput!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],
      $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
    addAwGcProductToCart(input: {
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
        aw_giftcard_option: $awGcInput
      }
    }) {
      cart {
        id
        items {
          ... on AwGiftCardCartItem {
            quantity
            prices {
              price {
                value
              }
            }
            product {
              name
            }
          }
        }
      }
    }
  }
`;
const customizable_options = `
customizable_options {
  id
  label
  is_required
  sort_order
  values {
    label
    value
    price {
      type
      units
      value
    }
  }
}
`;

const items = `
items {
  id
  note
  quantity
  ... on SimpleCartItem {
    SimpleMiniCustomizable: ${customizable_options}
  }

  ... on VirtualCartItem {
    virutalItemCustomizable: ${customizable_options}
  }
  ... on ConfigurableCartItem {
    ConfigurableMiniCustomizable: ${customizable_options}
      configurable_options {
      option_label
      value_label
    }
  }
  ... on BundleCartItem {
    bundle_options {
      label
      type
      values {
        label
        price
        quantity
      }
    }
  }
  ... on DownloadableCartItem {
    downloadablItemCustomizable: ${customizable_options}
    links {
      title
    }
  }
  ... on AwGiftCardCartItem {
    aw_giftcard_option {
      label
      value
    }
  }
  prices {
    discounts {
      amount {
        currency
        value
      }
    }
    price {
      value
      currency
    }
    price_including_tax {
      value
      currency
    }
    row_total_including_tax {
      currency
      value
    }
    total_item_discount {
      currency
      value
    }
  }
  product {
    id
    name
    small_image {
      url
      label
    }
    url_key
    sku
    stock_status
    seller {
      seller_id
      seller_name
    }
    categories {
      name
    }
  }
}
`;

// CtqQuoteListAddProduct
export const CtqQuoteListAddProduct = gql`
    mutation CtqQuoteListAddProduct(
      $productId:Int!,
      $quantity: Int!,
      $notes : String,
      $specification_form_id: Int
      ){
        CtqQuoteListAddProduct(
          input: {
            product_id: $productId
            qty: $quantity
            note : $notes
            specification_form_id: $specification_form_id
          }
        ) {
          cart {
            id
            email
            ${items}
          }
        }
    }
`;

export default {
    createCartIdGuest,
};
